export function getResultDetails(sector, translation) {
  return [sector.facing, sector.isolation].map((param, index) => {
    const type = index ? 'isolationParams' : 'facingParams'

    return Object.entries(param).map(([key, value]) => {
      const unit = materialUnits[`${key}`]
      const isObject = typeof value === 'object'

      return {
        title: translation.constructionParams[`${type}`][`${key}`],
        value: isObject ? value.title : value,
        unit: isObject ? null : translation.units[`${unit}`]
      }
    })
  }).flat()
}

const materialUnits = {
  density: 'pa',
  thickness: 'mm',
  sheets: 'pc'
}
