<template>
  <section class="export" ref="export">
    <h2 class="export__title">
      {{ $t('message.export.title') }}
    </h2>
    <div class="commands__list">
      <button
        class="commands__item"
        v-for="(command, index) of commands" :key="index"
        :style="{ 'grid-area': command.type }"
        @click="handle(command.type)"
      >
        <p class="commands__icon">
          <span v-html="getIcon('file')" class="icon"></span>
          <span class="type">{{ command.type.toUpperCase() }}</span>
        </p>
        <span class="commands__title">{{ `${$t('message.buttons.download')} ${command.type.toUpperCase()}` }}</span>
      </button>
      <article class="saving">
        <img class="saving__img" src="~@/assets/img/elements/saving__snip.png" alt=""/>
        <h4 class="saving__title">
          <a class="saving__button saving__button--save" @click="save('save')">
            {{ $t('message.buttons.save') }}
          </a>
        </h4>
        <button class="saving__button saving__button--auth" @click="save('auth')">
          {{ $t('message.export.auth') }}
        </button>
      </article>
    </div>
  </section>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  name: 'Export',
  mixins: [IconMixin],
  data: () => ({}),
  computed: {
    commands() {
      return [
        { type: 'pdf' }
      ]
    }
  },
  methods: {
    handle(action) {
      this.$emit('export', { action })
    },
    save(action) {
      this.$emit('save', { action })
    }
  },
  beforeDestroy() {
    this.$root.onBodyScroll()
  }
}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:600&display=swap')

.export
  grid-area: export
  margin-bottom: rem(50)
  +media((padding: (0: rem(30), 1170: rem(50))))
  border-radius: $block-radius
  background: $white
  .commands
    &__list
      display: grid
      grid-template-columns: repeat(2, 1fr) 2fr
      +media((grid-template-columns: (0: repeat(2, 1fr), 660: repeat(2, 1fr) 2fr, 768: repeat(2, 1fr),  970: repeat(2, 1fr) 2fr)))
      +media((grid-template-areas: (0: 'pdf pdf' 'saving saving', 660: 'pdf saving saving' 'pdf saving saving', 768: 'pdf pdf' 'saving saving', 970: 'pdf saving saving' 'pdf saving saving')))
      grid-gap: rem(10)
    &__item
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      height: 100%
      padding: rem(20) rem(15)
      background: none
      border: 1px solid $border-color
      border-radius: $radius
      cursor: pointer
    &__icon
      position: relative
      margin: 0
      .icon
        width: 100%
        height: 100%
      .type
        position: absolute
        bottom: 0
        left: 50%
        transform: translate(-50%, -50%)
        font-size: rem(11)
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        color: $main-color
    &__title
      margin-top: rem(13)
      @extend %18
      font-weight: 600
      line-height: 1.3
  .saving
    display: grid
    display: none
    grid-area: saving
    grid-template-rows: rem(30) 1fr
    grid-template-columns: rem(85) auto auto
    +media((grid-template-areas: (0: 'image title title' 'image auth auth', 1100: 'image title title' 'image auth auth')))
    grid-gap: 0 rem(20)
    height: rem(162)
    padding: rem(20) rem(15)
    border-radius: $block-radius
    border: 1px solid $border-color
    &__img
      grid-area: image
      display: flex
      max-width: rem(85)
      border-radius: rem(5)
      overflow: hidden
    &__title
      grid-area: title
      margin: 0
      +media((min-width: (0: auto, 450: rem(190))))
    &__button
      padding: 0
      text-align: left
      background: none
      border: none
      cursor: pointer
      &--save
        font-weight: 600
      &--auth
        grid-area: auth
        align-self: self-start
        color: $text-gray
        @extend %14
</style>
